<template>
    <div class="myCourse">
        <div class="courseList flex alignCenter spacearound" v-for="(im,ix) in courseList[page-1]" :key="ix" @click="goother({path:'/play',query:{id: im.id}})">
            <div class="pic">
                <img :src="im.face_img" :alt="im.title">
                <div class="studyCount flex alignCenter spacearound">{{im.study_count}}在学</div>
            </div>
            <div class="myCourseRight flex flexColumn spacearound">
                <span class="c33" style="font-size:18px;">{{im.title}}</span>
                <span style="color:#919191;font-size:13px;">{{im.type == 1 ? '直播时间：' : '时长：'}}&nbsp;&nbsp;&nbsp;{{im.duration}}</span>
                <span></span>
            </div>
        </div>
        <el-pagination
        style="margin-top:37px;"
        background
        layout="prev, pager, next"
        :total="dataCount"
        :page-size="5"
        @current-change="currentChange">
        </el-pagination>
    </div>
</template>
<script>
import { my_course } from '@/utils/Api/userList'
export default {
    name:'myCourse',
    data(){
        return{
            courseList:[],
            page: 1,
            dataCount: 0
        }
    },
    created(){
        this.inititalBefore()
    },
    methods:{
        inititalBefore(){
            let params = {
                page: this.page,
                limit: 5
            }
            this.initial(params)
        },
        async initial(params){
            let {res, count} = await my_course(params)
            console.log(res, count)
            if(count > 0){
                for(let i = 0; i < Math.ceil(count/10); i++){
                    this.courseList.push([])
                }
                this.courseList[this.page-1] = res
                this.dataCount = count
            }
        },
        /* 改变页数 */
        currentChange(value){
            this.page = value
            this.inititalBefore()
            this.scrollTop()
        }
    }
}
</script>
<style lang="scss" scoped>
.myCourseRight{
    flex: 1;
    margin-left: 38px;
    height: 160px;
    text-align: left;
}
.myCourse{
    margin-top: 20px;
    .courseList{
        width: 887px;
        height: 192px;
        opacity: 1;
        background: #f8f8f8;
        margin-bottom: 10px;
        margin-left: 20px;
        .pic{
            width: 260px;
            height: 160px;
            position: relative;
            margin-left: 16px;
            background: #fff;
            .studyCount{
                width: 100%;
                height: 43px;
                background: rgba(0, 0, 0, 0.6);
                color: #fff;
                font-size: 15px;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
        img{
            width: 100%;
            height: 100%;
        }
    }
}
</style>